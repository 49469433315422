import { ColorConfig } from '#imports'
import { Range } from '@dev.smartpricing/sp-shared-network-common'

export function getCompetitorBadgeType(score: number, maxScore: number = 10) {
	if (score < 0.5 * maxScore) {
		return SpBadgeType.Low
	} else if (score < 0.75 * maxScore) {
		return SpBadgeType.Mid
	} else {
		return SpBadgeType.High
	}
}

export function isMarketIntelligenceEnabledOnAccommodation(accommodation: Accommodation) {
	return accommodation.purchases.marketIntelligence.permissions.access
}

export function formatTooltip(
	label: string,
	colorConfig: {
		area?: string[]
		legend: string
	},
	value: number,
	additionalInfo: string[],
	type: 'currency' | 'percentage' = 'currency'
) {
	const { area, legend } = colorConfig
	const formattedValue = type === 'currency' ? utilCurrency.formatPrice(value, { code: 'EUR' }) : `${value}%`

	const priceRow = [
		`<div class="flex flex-col px-2 py-1 rounded-md ${area?.join(' ')}">
            <div class="w-full flex items-center justify-start gap-2 text-xs font-normal leading-5">
                <div class="w-[9px] h-[9px] rounded-sm flex-shrink-0" style="background: ${legend}"></div>
                    <div class="min-w-0 flex-1 overflow-hidden text-ellipsis">${label}</div>
                    <div>${formattedValue}</div>
                </div>`,
	]

	if (additionalInfo.length) {
		priceRow.push(
			`<div class="text-secondary-gray-600 text-xs font-medium leading-[18px] tracking-[0.32px] ml-auto">${additionalInfo[0]}</div>`
		)
	}

	priceRow.push('</div>')

	return priceRow.join('')
}

export function formatRangeTooltip(
	label: string,
	colorConfig: {
		area: string[]
		legend: string
	},
	range: Range<number>,
	type: 'currency' | 'percentage' = 'currency'
) {
	const { from, to } = range
	const { area, legend } = colorConfig

	let formattedMinValue: string = from.toString()
	let formattedMaxValue: string = to.toString()
	if (type === 'currency') {
		formattedMinValue = utilCurrency.formatPrice(Number(from) === 0 ? undefined : Number(to), {
			code: 'EUR',
		})
		formattedMaxValue = utilCurrency.formatPrice(Number(to) === 0 ? undefined : Number(from), {
			code: 'EUR',
		})
	} else {
		formattedMinValue = `${from}%`
		formattedMaxValue = `${to}%`
	}

	return `<div class="w-full flex items-center justify-start gap-2 px-2 py-1 rounded-md text-xs font-normal leading-5 ${area.join(' ')}">
        <div class="w-[9px] h-[9px] rounded-sm flex-shrink-0" style="background: ${legend}"></div>
        <div class="min-w-0 flex-1 overflow-hidden text-ellipsis">${label}</div>
        <div>${formattedMinValue} ↔ ${formattedMaxValue}</div>
    </div>`
}
