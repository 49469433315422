import {
	MarketIntelligenteCompetitor,
	MarketIntelligenceCompetitorPrice,
} from '../submodules/sharedTypes/common/MarketIntelligence'

export type MarketIntelligenceCompetitorPriceForMap = Omit<MarketIntelligenceCompetitorPrice, 'hotelId'>

export type MarketIntelligenceCompetitorForList = MarketIntelligenteCompetitor & {
	isSelectedInCompetitorsList: boolean
	isSelectedInChart: boolean
	isLoading: boolean
}

export enum MarketIntelligenceChartSeriesKind {
	CurrentAccommodation = 'currentAccommodation',
	MeanMarketTrend = 'meanMarketTrend',
	MinMaxmarketTrend = 'minMaxMarketTrend',
	SmarpricingStrategy = 'smartpricingStrategy',
	MinMaxOccupancyTrend = 'minMaxOccupancyTrend',
	CurrentAccommodationOccupancy = 'currentAccommodationOccupancy',
}
