import { addDays, addYears } from 'date-fns'
import { defineStore } from 'pinia'
import { calendarConfig } from '../config/CalendarConfig'
import { LoadingIds } from '../constants/loadingIds'
import { GetAccommodationsOccupanciesResponse } from '../submodules/sharedTypes/communication/accommodations/occupancies/GetAccommodationsOccupanciesResponse'
import { utilNetwork } from '../utils/UtilNetwork'
import { Occupancy } from './../submodules/sharedTypes/common/Occupancy'
import { GetAccommodationsOccupanciesNetworkObject } from './../submodules/sharedTypes/communication/accommodations/occupancies/GetAccommodationsOccupanciesNetworkObject'
import { useAccommodationsStore } from './accommodations'
import { useLoadingStore } from './loading'
import { GetAccommodationsMarketOccupancydNetworkObject } from '../submodules/sharedTypes/communication/accommodations/market-occupancy/GetAccommodationsMarketOccupancyNetworkObject'
import { GetAccommodationsMarketOccupancyResponse } from '../submodules/sharedTypes/communication/accommodations/market-occupancy/GetAccommodationsMarketOccupancyResponse'
import { MarketOccupancy } from '../submodules/sharedTypes/common/MarketOccupancy'

export const useOccupancyStore = defineStore('occupancy', () => {
	const { getPromiseDependency } = useLoadingStore()

	const startDate = ref<Date>(new Date())
	const setStartDate = (date: Date) => {
		startDate.value = date
	}

	// Accommodation occupancy
	const accommodationOccupancy = ref<Map<number | string, Occupancy[]>>(new Map())
	const accommodationOccupancyById = computed(() => (id: number) => accommodationOccupancy.value.get(id) || [])

	const roomTypeOccupancy = ref<Map<number | string, Occupancy[]>>(new Map())
	const roomTypeOccupancyById = computed(() => (id: number) => roomTypeOccupancy.value.get(id) || [])

	const requestOccupancy = async (requestYearlyOccupancy: boolean = false) => {
		clearOccupancy()

		await getPromiseDependency(LoadingIds.ACCOMMODATIONS)

		const accommodations = useAccommodationsStore().accommodationsToShow.length
			? useAccommodationsStore().accommodationsToShow
			: useAccommodationsStore().accommodations.map((element) => element.id)

		const request = new GetAccommodationsOccupanciesNetworkObject({
			accommodationIds: accommodations,
			dateRange: {
				from: startDate.value,
				to: requestYearlyOccupancy
					? addYears(startDate.value, 1)
					: addDays(startDate.value, calendarConfig.maxDays - 1),
			},
		})

		utilNetwork.simpleRequest(request, undefined, LoadingIds.OCCUPANCY)
	}
	const setOccupanciesFromResponse = (res: GetAccommodationsOccupanciesResponse) => {
		const localOccupancies: {
			accommodations: Record<number | string, Occupancy[]>
			roomTypes: Record<number | string, Occupancy[]>
		} = {
			accommodations: {},
			roomTypes: {},
		}

		res.accommodations.forEach((accommodationPrice) => {
			accommodationPrice.occupancies.forEach((occupancy) => {
				const destination = occupancy.roomTypeId == undefined ? 'accommodations' : 'roomTypes'
				const indexToUse = occupancy.roomTypeId == undefined ? occupancy.accommodationId : occupancy.roomTypeId

				if (!localOccupancies[destination][indexToUse]) localOccupancies[destination][indexToUse] = []
				localOccupancies[destination][indexToUse].push(occupancy)
			})
		})

		Object.keys(localOccupancies.accommodations).forEach((accommodationId) => {
			accommodationOccupancy.value.set(accommodationId, localOccupancies.accommodations[accommodationId])
		})
		Object.keys(localOccupancies.roomTypes).forEach((roomTypeId) => {
			roomTypeOccupancy.value.set(Number(roomTypeId), localOccupancies.roomTypes[roomTypeId])
		})
	}

	// Market occupancy
	const marketOccupancy = ref<Map<string, MarketOccupancy>>(new Map())
	const marketOccupancyByAccommodationId = computed(
		() => (id: string) => marketOccupancy.value.get(id)?.occupancy || []
	)

	const requestMarketOccupancy = async (requestYearlyOccupancy: boolean = false) => {
		clearMarketOccupancy()

		await getPromiseDependency(LoadingIds.ACCOMMODATIONS)

		const accommodations = useAccommodationsStore().accommodationsToShow.length
			? useAccommodationsStore().accommodationsToShow
			: useAccommodationsStore().accommodations.map((element) => element.id)

		utilNetwork.simpleRequest(
			new GetAccommodationsMarketOccupancydNetworkObject({
				accommodationIds: accommodations,
				dateRange: {
					from: startDate.value,
					to: requestYearlyOccupancy
						? addYears(startDate.value, 1)
						: addDays(startDate.value, calendarConfig.maxDays - 1),
				},
			})
		)
	}
	const setMarketOccupancyFromResponse = async (res: GetAccommodationsMarketOccupancyResponse) => {
		res.occupancies.forEach((el) => {
			marketOccupancy.value.set(el.id, el)
		})
	}

	// Utils
	const clearMarketOccupancy = () => {
		marketOccupancy.value.clear()
	}
	const clearOccupancy = () => {
		accommodationOccupancy.value.clear()
		roomTypeOccupancy.value.clear()
	}

	const $reset = () => {
		clearOccupancy()
		clearMarketOccupancy()

		startDate.value = new Date()
	}

	return {
		startDate,
		setStartDate,

		// Accommodation occupancy
		accommodationOccupancy,
		accommodationOccupancyById,

		roomTypeOccupancy,
		roomTypeOccupancyById,

		requestOccupancy,
		setOccupanciesFromResponse,

		// Market occupancy
		marketOccupancy,
		marketOccupancyByAccommodationId,
		requestMarketOccupancy,
		setMarketOccupancyFromResponse,

		// Utils
		clearOccupancy,
		clearMarketOccupancy,
		$reset,
	}
})
