import { ChannelManagerURLs } from './ChannelManager';
import { MapCoordinates } from './MapCoordinates';

export enum MarketIntelligenceCompetitorEditOperation {
  Add = 'ADD',
  AddStrategy = 'ADD_STRATEGY',
  Remove = 'REMOVE',
  RemoveStrategy = 'REMOVE_STRATEGY',
  Freeze = 'FREEZE',
  Unfreeze = 'UNFREEZE',
  AckDeprecated = 'ACK_DEPRECATED',
}

export enum MarketIntelligenceMarketKind {
  MarketIntelligence = 'mi',
  MarketIntelligenceRecent = 'mi_recent',
  Strategy = 'strategy',
  StrategyRecent = 'strategy_recent',
}

export enum MarketIntelligenceMarketPriceKind {
  MeanMarketPrice = 'meanMarketPrice',
  MinMaxMarketPrice = 'minMaxMarketPrice',
}

export type MarketIntelligenceCompetitorEdit = {
  operation: MarketIntelligenceCompetitorEditOperation;
  hotel_id: number;
};

export type MarketIntelligenteCompetitor = {
  id: number;
  name: string;
  coordinates: MapCoordinates;
  distance: number;
  thumbnailURL: string;
  channelManagerURLs: ChannelManagerURLs;
  score: number;
  relevance: number | null;
  stars: number;
  isClosed: boolean;
  isFrozen: boolean;
  isDeprecated: boolean;
  isAddedByUser: boolean;
  isDisabledByUser: boolean;
  isDiscoveredByAlgorithm: boolean;
  isInStrategyMarket: boolean;
};

export type MarketIntelligenceCompetitorForSearch = Omit<
  MarketIntelligenteCompetitor,
  | 'isClosed'
  | 'isDeprecated'
  | 'isAddedByUser'
  | 'isDisabledByUser'
  | 'isDiscoveredByAlgorithm'
  | 'isInStrategyMarket'
> & {
  address: string;
};

export type MarketIntelligenceCompetitorPrice = {
  date: string;
  price?: number;
  updatedAt?: string;
};

export type MarketIntelligenceAccommodationPrice = Omit<
  MarketIntelligenceCompetitorPrice,
  'hotelId'
>;

export type MarketIntelligenceAccommodation = {
  coordinates: MapCoordinates;
} & (
  | {
      useBookingPrices: false;
    }
  | {
      useBookingPrices: true;
      bookingPrices: MarketIntelligenceAccommodationPrice[];
    }
);

export type MarketIntelligenceMeanMarketPrice = {
  date: string;
  marketMeanPrice: number;
};

export type martketIntelligenceMinMaxMarketPrice = {
  date: string;
  marketLowerPrice: number;
  marketUpperPrice: number;
};

export type MarketIntelligenceMarketPrices = Record<
  MarketIntelligenceMarketKind,
  {
    [MarketIntelligenceMarketPriceKind.MeanMarketPrice]: MarketIntelligenceMeanMarketPrice[];
    [MarketIntelligenceMarketPriceKind.MinMaxMarketPrice]: martketIntelligenceMinMaxMarketPrice[];
  }
>;
