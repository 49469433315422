import { defineStore } from 'pinia'
import {
	MarketIntelligenceCompetitorPrice,
	MarketIntelligenceAccommodation,
	MarketIntelligenceMarketPrices,
	MarketIntelligenceMarketKind,
	MarketIntelligenceMarketPriceKind,
	MarketIntelligenteCompetitor,
	MarketIntelligenceCompetitorEditOperation,
} from '../submodules/sharedTypes/common/MarketIntelligence'
import { GetAccommodationMarketIntelligenceDataResponse } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/GetAccommodationMarketIntelligenceDataResponse'
import { GetAccommodationMarketIntelligenceDataRequest } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/GetAccommodationMarketIntelligenceDataRequest'
import { GetAccommodationMarketIntelligenceDataNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/GetAccommodationMarketIntelligenceDataNetworkObject'
import { GetAccommodationMarketIntelligenceCompetitorsPricesNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/GetAccommodationMarketIntelligenceCompetitorsPricesNetworkObject'
import { GetAccommodationMarketIntelligenceCompetitorsPricesResponse } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/GetAccommodationMarketIntelligenceCompetitorsPricesResponse'
import { GetAccommodationMarketIntelligenceCompetitorsPricesRequest } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/GetAccommodationMarketIntelligenceCompetitorsPricesRequest'
import {
	MarketIntelligenceChartSeriesKind,
	MarketIntelligenceCompetitorForList,
	MarketIntelligenceCompetitorPriceForMap,
} from '../types/MarketIntelligence'
import { Core } from '../config/Core'
import { ChartType } from '../types/Chart'
import { FeedbackId } from '../constants/FeedbackId'
import { TrackingMessages } from '../constants/trackingMessages'
import { EditAccommodationMarketIntelligenceCompetitorNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/EditAccommodationMarketIntelligenceCompetitorNetworkObject'
import { ResetAccommodationMarketIntelligenceCompetitorSetNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/ResetAccommodationMarketIntelligenceCompetitorSetNetworkObject'
import { addYears } from 'date-fns'
import { AnonymizationStructure } from '../config/SalesModesConfig'
import { cloneDeep } from 'lodash'
import { Accommodation } from '../types/Accommodation'
import { EditAccommodationMarketIntelligenceCompetitorRequest } from '../submodules/sharedTypes/communication/accommodations/id/market-intelligence/EditAccommodationMarketIntelligenceCompetitorRequest'

export const useMarketIntelligenceStore = defineStore('market-intelligence', () => {
	const accommodationsStore = useAccommodationsStore()
	const { accommodations } = storeToRefs(accommodationsStore)

	const pricesStore = usePricesStore()
	const { getPriceByRoomTypeId } = pricesStore

	const accommodation = ref<MarketIntelligenceAccommodation | undefined>(undefined)
	const selectedAccommodation = ref<Accommodation>(
		accommodations.value.find((accommodation) => isMarketIntelligenceEnabledOnAccommodation(accommodation))!
	)
	const marketCompleteness = ref<number>()

	const competitors = ref<MarketIntelligenceCompetitorForList[]>([])
	const strategyMarketCompetitors = computed(() =>
		competitors.value.filter((competitor) => competitor.isInStrategyMarket)
	)

	const competitorsPrices = ref<Record<number, MarketIntelligenceCompetitorPriceForMap[]>>({})
	const competitorsHistoricalPrices = ref<Record<number, MarketIntelligenceCompetitorPriceForMap[]>>({})

	const marketPrices = ref<MarketIntelligenceMarketPrices | undefined>(undefined)

	const isLoadingMarketIntelligenceData = ref(false)
	const isLoadingMarketIntelligenceCompetitorsPrices = ref(false)

	const isCompetitorPricesFetched = computed(() => (competitorId: number) => {
		if (competitorsPrices.value[competitorId]) {
			return true
		}

		return false
	})

	const shouldFetchCompetitorPrices = computed(() => (competitorId: number) => {
		if (!competitorsPrices.value[competitorId]) {
			return true
		}

		return false
	})

	const activeChartSeries = ref<Record<string, boolean>>({
		[MarketIntelligenceChartSeriesKind.CurrentAccommodation]: true,
		[MarketIntelligenceChartSeriesKind.MeanMarketTrend]: true,
		[MarketIntelligenceChartSeriesKind.SmarpricingStrategy]: true,
		[MarketIntelligenceChartSeriesKind.MinMaxmarketTrend]: true,
		[MarketIntelligenceChartSeriesKind.MinMaxOccupancyTrend]: true,
		[MarketIntelligenceChartSeriesKind.CurrentAccommodationOccupancy]: true,
	})
	const marketPricesChartData = computed(() => [
		{
			type: ChartType.Line.toLowerCase(),
			id: MarketIntelligenceChartSeriesKind.CurrentAccommodation,
			data: accommodation.value?.useBookingPrices
				? accommodation.value.bookingPrices?.map((price) => [price.date, price.price])
				: selectedAccommodationPrices.value.map((price) => [price.date, price.publishedPrice]),
			name: selectedAccommodation.value?.name ?? useLocale().translate(TranslationKeys.YOUR_ACCOMMODATION),
			color: Core.colors.charts.line.accommodation,
			showSymbol: false,
		},
		{
			type: ChartType.Line.toLowerCase(),
			id: MarketIntelligenceChartSeriesKind.MeanMarketTrend,
			data: marketPrices.value?.[MarketIntelligenceMarketKind.MarketIntelligenceRecent][
				MarketIntelligenceMarketPriceKind.MeanMarketPrice
			].map((price) => [price.date, price.marketMeanPrice]),
			name: useLocale().translate(TranslationKeys.MEAN_MARKET_TREND),
			color: Core.colors.charts.line.marketTrend,
			showSymbol: false,
		},
		{
			type: ChartType.Custom.toLowerCase(),
			id: MarketIntelligenceChartSeriesKind.MinMaxmarketTrend,
			data: marketPrices.value?.[MarketIntelligenceMarketKind.MarketIntelligenceRecent][
				MarketIntelligenceMarketPriceKind.MinMaxMarketPrice
			].map((price) => [price.date, price.marketLowerPrice, price.marketUpperPrice]),
			name: useLocale().translate(TranslationKeys.MIN_MAX_MARKET),
			color: Core.colors.charts.line.marketThreshold,
			renderItem: function (_params: any, api: any) {
				const dataPoints = marketPrices.value![MarketIntelligenceMarketKind.MarketIntelligenceRecent][
					MarketIntelligenceMarketPriceKind.MinMaxMarketPrice
				].map((price) => {
					const date = price.date
					const min = price.marketLowerPrice
					const max = price.marketUpperPrice

					const yMin = api.coord([date, min])
					const yMax = api.coord([date, max])

					return [yMin, yMax]
				})

				const polygonPoints: any[] = []
				dataPoints.forEach((pointPair) => {
					polygonPoints.push(pointPair[0])
				})

				for (let i = dataPoints.length - 1; i >= 0; i--) {
					polygonPoints.push(dataPoints[i][1])
				}

				return {
					type: 'group',
					children: [
						{
							type: 'polygon',
							shape: { points: polygonPoints },
							style: {
								stroke: null,
								fill: Core.colors.charts.line.marketThreshold.serie.hex,
							},
						},
					],
				}
			},
		},
		...competitors.value
			.filter((competitor) => competitor.isSelectedInCompetitorsList)
			.map((competitor, index) => {
				const competitorPrices = competitorsPrices.value[competitor.id]
				return {
					id: competitor.id,
					data: competitorPrices?.map((price) => [price.date, price.price]),
					type: 'line',
					name: competitor.name,
					color: getColorData()[index],
					showSymbol: false,
				}
			}),
		...competitors.value
			.filter((competitor) => competitor.isSelectedInCompetitorsList)
			.map((competitor, index) => {
				const competitorPrices = competitorsHistoricalPrices.value[competitor.id]
				return {
					id: `${competitor.id}_historical`,
					data: competitorPrices?.map((price) => [price.date, price.price, price.updatedAt]),
					type: 'line',
					name: competitor.name,
					color: getColorData()[index],
					showSymbol: false,
					lineStyle: {
						type: 'dotted',
					},
				}
			}),
	])

	const marketOccupancyChartData = computed(() => {
		const marketOccupancy = useOccupancyStore().marketOccupancyByAccommodationId(selectedAccommodation.value.id)
		const accommodationOccupancy = useOccupancyStore().accommodationOccupancyById(selectedAccommodation.value.id)

		return [
			{
				type: ChartType.Line.toLowerCase(),
				id: MarketIntelligenceChartSeriesKind.CurrentAccommodationOccupancy,
				data: accommodationOccupancy.map((occupancy) => [occupancy.date, occupancy.occupancy * 100]),
				name: useLocale().translate(TranslationKeys['competitors.chart_your_occupancy.LABEL']),
				color: Core.colors.charts.line.currentAccommodationOccupancy,
				showSymbol: false,
			},
			{
				id: `${MarketIntelligenceChartSeriesKind.MinMaxOccupancyTrend}_stacked`,
				data: marketOccupancy?.map((occupancy) => {
					if (occupancy.lower != null && occupancy.upper != null) {
						return [occupancy.date, occupancy.lower * 100]
					}

					return [occupancy.date, null]
				}),
				type: 'line',
				name: useLocale().translate(TranslationKeys['competitors.chart_market_occupancy.LABEL']),
				showSymbol: false,
				color: Core.colors.charts.line.minMaxOccupancyTrend,
				stack: `${MarketIntelligenceChartSeriesKind.MinMaxOccupancyTrend}_stacked`,
				lineStyle: {
					color: 'transparent',
					width: 0,
				},
			},
			{
				id: MarketIntelligenceChartSeriesKind.MinMaxOccupancyTrend,
				data: marketOccupancy?.map((occupancy) => {
					if (occupancy.lower != null && occupancy.upper != null) {
						return [occupancy.date, occupancy.upper * 100 - occupancy.lower * 100]
					}

					return [occupancy.date, null]
				}),
				color: Core.colors.charts.line.minMaxOccupancyTrend,
				areaStyle: {
					color: Core.colors.charts.line.minMaxOccupancyTrend.serie,
					opacity: 1,
				},
				lineStyle: {
					width: 0,
				},
				type: 'line',
				name: useLocale().translate(TranslationKeys['competitors.chart_market_occupancy.LABEL']),
				showSymbol: false,
				stack: `${MarketIntelligenceChartSeriesKind.MinMaxOccupancyTrend}_stacked`,
			},
		]
	})

	function getMarketIntelligenceData(params: GetAccommodationMarketIntelligenceDataRequest) {
		setIsLoadingMarketIntelligenceData(true)

		return Promise.all([
			pricesStore.requestPrices({ from: new Date(), to: addYears(new Date(), 1) }),
			utilNetwork.simpleRequest(
				new GetAccommodationMarketIntelligenceDataNetworkObject(params),
				setMarketIntelligenceData
			),
		])
	}

	/**
	 * Does the same of getMarketIntelligenceData, but do not set the loading state, in this way
	 * we prevent to show the laoding spinner when we are fetching the data for the first time
	 */
	function updateMarketIntelligenceData(params: GetAccommodationMarketIntelligenceDataRequest) {
		return utilNetwork.simpleRequest(
			new GetAccommodationMarketIntelligenceDataNetworkObject(params),
			setMarketIntelligenceData
		)
	}

	function getMarketIntelligenceCompetitorPrices(params: GetAccommodationMarketIntelligenceCompetitorsPricesRequest) {
		setIsLoadingMarketIntelligenceCompetitorsPrices(true)

		return utilNetwork.simpleRequest(
			new GetAccommodationMarketIntelligenceCompetitorsPricesNetworkObject(params),
			setMarketIntelligenceCompetitorsPrices
		)
	}

	function setSelectedAccommodation(receivedAccommodation: MarketIntelligenceAccommodation) {
		accommodation.value = receivedAccommodation
	}

	const selectedAccommodationPrices = computed(() => {
		const selectedAccommodationRoomType = selectedAccommodation.value?.roomTypes?.[0]

		if (!selectedAccommodationRoomType) {
			return []
		}

		return getPriceByRoomTypeId(selectedAccommodationRoomType.id as number)
	})

	function setCompetitors(receivedCompetitors: MarketIntelligenteCompetitor[]) {
		competitors.value = receivedCompetitors.map((competitor) => ({
			...competitor,
			isLoading: false,
			isSelectedInCompetitorsList: false,
			isSelectedInChart: false,
		}))
	}

	function setMarketIntelligenceData(response: GetAccommodationMarketIntelligenceDataResponse) {
		setSelectedAccommodation(response.accommodation)
		setCompetitors(response.competitors)
		setMarketPrices(response.market)
		marketCompleteness.value = response.marketCompleteness

		setIsLoadingMarketIntelligenceData(false)
	}

	function setCompetitorsPrices(receivedCompetitorsPrices: Record<number, MarketIntelligenceCompetitorPrice[]>) {
		Object.entries(receivedCompetitorsPrices).forEach(([competitorId, prices]) => {
			competitorsPrices.value[Number(competitorId)] = prices
		})
	}

	function setCompetitorsHistoricalPrices(
		receivedCompetitorsPrices: Record<number, MarketIntelligenceCompetitorPrice[]>
	) {
		Object.entries(receivedCompetitorsPrices).forEach(([competitorId, prices]) => {
			competitorsHistoricalPrices.value[Number(competitorId)] = prices
		})
	}

	function setMarketPrices(receivedMarketPrices: MarketIntelligenceMarketPrices) {
		marketPrices.value = receivedMarketPrices
	}

	function setMarketIntelligenceCompetitorsPrices(
		response: GetAccommodationMarketIntelligenceCompetitorsPricesResponse
	) {
		setCompetitorsPrices(response.prices)
		setCompetitorsHistoricalPrices(response.prices_historical)

		setIsLoadingMarketIntelligenceCompetitorsPrices(false)
	}

	function setIsLoadingMarketIntelligenceData(isLoading: boolean) {
		isLoadingMarketIntelligenceData.value = isLoading
	}

	function setIsLoadingMarketIntelligenceCompetitorsPrices(isLoading: boolean) {
		isLoadingMarketIntelligenceCompetitorsPrices.value = isLoading
	}

	function setSelectedAccommodationById(accommodationId: string) {
		// @ts-ignore
		selectedAccommodation.value = accommodations.value.find((accommodation) => accommodation.id === accommodationId)
	}

	async function addCompetitor(competitor: MarketIntelligenteCompetitor, includdeInStrategyMarket: boolean = false) {
		useFeedbackStore().requestFeedback(FeedbackId.Add3OrMoreCompetitors)

		// add a placeholder to show the skeleton
		competitors.value.unshift({
			...competitor,
			isLoading: true,
			isSelectedInChart: false,
			isSelectedInCompetitorsList: false,
			isAddedByUser: true,
			isInStrategyMarket: includdeInStrategyMarket,
		})

		const edits: EditAccommodationMarketIntelligenceCompetitorRequest['edits'] = [
			{
				hotel_id: competitor.id,
				operation: MarketIntelligenceCompetitorEditOperation.Add,
			},
		]

		if (includdeInStrategyMarket) {
			edits.push({
				hotel_id: competitor.id,
				operation: MarketIntelligenceCompetitorEditOperation.AddStrategy,
			})
		}

		// save the competitor
		await utilNetwork.simpleRequest(
			new EditAccommodationMarketIntelligenceCompetitorNetworkObject({
				id: selectedAccommodation.value!.id as string,
				edits,
			}),
			() => {}
		)

		await updateMarketIntelligenceData({ id: selectedAccommodation.value!.id as string })

		// Track action
		utilTracking.track(TrackingMessages.COMPETITOR_ADD, {
			competitor_name: competitor.name,
			competitor_url: competitor.channelManagerURLs['booking.com'],
			competitor_score: competitor.score,
			competitor_relevance: competitor.relevance,
		})
	}

	// Actions on competitor
	async function toggleCompetitorFromStrategyMarket(
		id: number,
		operation:
			| MarketIntelligenceCompetitorEditOperation.AddStrategy
			| MarketIntelligenceCompetitorEditOperation.RemoveStrategy
	) {
		await utilNetwork.simpleRequest(
			new EditAccommodationMarketIntelligenceCompetitorNetworkObject({
				id: selectedAccommodation.value!.id as string,
				edits: [
					{
						hotel_id: id,
						operation,
					},
				],
			}),
			() => {}
		)

		// set competitor as loading
		const competitor = competitors.value.find((competitor) => competitor.id === id)
		if (competitor) {
			competitor.isLoading = true
		}

		await updateMarketIntelligenceData({ id: selectedAccommodation.value!.id as string })

		// set competitor as loaded
		if (competitor) {
			competitor.isLoading = false
		}
	}

	async function removeCompetitor(id: number) {
		await utilNetwork.simpleRequest(
			new EditAccommodationMarketIntelligenceCompetitorNetworkObject({
				id: selectedAccommodation.value!.id as string,
				edits: [
					{
						hotel_id: id,
						operation: MarketIntelligenceCompetitorEditOperation.Remove,
					},
				],
			}),
			() => {}
		)

		// set competitor as loading
		const competitor = competitors.value.find((competitor) => competitor.id === id)
		if (competitor) {
			competitor.isLoading = true
		}

		await updateMarketIntelligenceData({ id: selectedAccommodation.value!.id as string })

		// set competitor as loaded
		if (competitor) {
			competitor.isLoading = false
		}
	}

	async function toggleCompetitorFreeze(
		id: number,
		operation: MarketIntelligenceCompetitorEditOperation.Freeze | MarketIntelligenceCompetitorEditOperation.Unfreeze
	) {
		await utilNetwork.simpleRequest(
			new EditAccommodationMarketIntelligenceCompetitorNetworkObject({
				id: selectedAccommodation.value!.id as string,
				edits: [
					{
						hotel_id: id,
						operation,
					},
				],
			}),
			() => {}
		)

		// set competitor as loading
		const competitor = competitors.value.find((competitor) => competitor.id === id)
		if (competitor) {
			competitor.isLoading = true
		}

		await updateMarketIntelligenceData({ id: selectedAccommodation.value!.id as string })

		// set competitor as loaded
		if (competitor) {
			competitor.isLoading = false
		}
	}

	function editCompetitor() {}

	async function restoreCompetitor(id: number) {
		await utilNetwork.simpleRequest(
			new EditAccommodationMarketIntelligenceCompetitorNetworkObject({
				id: selectedAccommodation.value!.id as string,
				edits: [
					{
						hotel_id: id,
						operation: MarketIntelligenceCompetitorEditOperation.Unfreeze,
					},
				],
			}),
			() => {}
		)

		// set competitor as loading
		const competitor = competitors.value.find((competitor) => competitor.id === id)
		if (competitor) {
			competitor.isLoading = true
		}

		await updateMarketIntelligenceData({ id: selectedAccommodation.value!.id as string })

		// set competitor as loaded
		if (competitor) {
			competitor.isLoading = false
		}
	}

	async function resetCompetitorSet() {
		await utilNetwork.simpleRequest(
			new ResetAccommodationMarketIntelligenceCompetitorSetNetworkObject({
				id: selectedAccommodation.value!.id as string,
			}),
			() => {}
		)

		await getMarketIntelligenceData({ id: selectedAccommodation.value!.id as string })
	}

	function toggleCompetitorSelection(competitorId: number) {
		const competitor = competitors.value.find((competitor) => competitor.id === competitorId)
		const actualCompetitor = competitors.value.find((competitor) => competitor.id === competitorId)
		if (!competitor || !actualCompetitor) {
			return
		}

		actualCompetitor.isSelectedInCompetitorsList = !competitor.isSelectedInCompetitorsList
		activeChartSeries.value[competitorId] = actualCompetitor.isSelectedInCompetitorsList

		if (shouldFetchCompetitorPrices.value(competitorId)) {
			getMarketIntelligenceCompetitorPrices({
				id: selectedAccommodation.value!.id as string,
			})
		}
	}

	async function anonymizeDeanonymizeCurrentAccommodation(mode: 'anon' | 'deanon', config?: AnonymizationStructure) {
		if (!selectedAccommodation.value) {
			return
		}

		const label = config ? useLocale().translate(config.accommodation) : ''
		const accommodation: Accommodation & { demoMode?: boolean } = cloneDeep(toRaw(selectedAccommodation.value))
		if (mode === 'anon') {
			if (accommodation.demoMode) {
				accommodation.name = accommodation.name
			}

			accommodation.name = label
			accommodation.demoMode = true
		} else {
			accommodation.name = accommodation.name
			accommodation.demoMode = false
		}

		selectedAccommodation.value = accommodation
	}

	return {
		accommodation,
		selectedAccommodation,
		marketCompleteness,
		selectedAccommodationPrices,

		competitors,
		strategyMarketCompetitors,
		competitorsPrices,

		isCompetitorPricesFetched,
		shouldFetchCompetitorPrices,
		activeChartSeries,
		marketPricesChartData,
		marketOccupancyChartData,

		isLoadingMarketIntelligenceData,
		isLoadingMarketIntelligenceCompetitorsPrices,

		getMarketIntelligenceData,
		updateMarketIntelligenceData,
		getMarketIntelligenceCompetitorPrices,

		setSelectedAccommodationById,

		addCompetitor,
		editCompetitor,
		toggleCompetitorFromStrategyMarket,
		removeCompetitor,
		toggleCompetitorFreeze,
		restoreCompetitor,
		resetCompetitorSet,
		toggleCompetitorSelection,

		anonymizeDeanonymizeCurrentAccommodation,
	}
})
